import { useOktaAuth } from '@okta/okta-react';
import React, { useContext} from 'react';
import { UserContext } from "../App";
import { useHistory, Link } from 'react-router-dom';
import Logo from "../logo.gif";
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

import { Divider } from '@mui/material/';

import { useQuery } from '@apollo/react-hooks';
import { VIEW_REG_CASES_BY_USER, AMEND_CASE_REG } from "../Queries";
import { Spinner } from 'reactstrap';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}));

const Navbar = ({ setCorsErrorModalOpen }) => {
  const userInfo = useContext(UserContext)

  const history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  /* Okta*/
  const {authState, oktaAuth } = useOktaAuth();

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');

  const login = async () => oktaAuth.signInWithRedirect();

  const logout = async () => {
    const basename = window.location.origin + history.createHref({ pathname: '/' });
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  /* Grab all Registrations related to this user */
  const userEmail = Boolean(userInfo) ? userInfo.email : null
  const viewCaseRegByUser = useQuery(VIEW_REG_CASES_BY_USER, { variables: {userEmail: userEmail}, fetchPolicy: 'network-only'})

  if (!userInfo || viewCaseRegByUser.loading){
    return <Spinner color="dark" />; // show spinner while loading.
  }

  if (viewCaseRegByUser.error){
    console.error(`Retrieve error! ${viewCaseRegByUser.error}`)
    return <React.Fragment>Error :( </React.Fragment>;
  }

  const caseRegistrations =  viewCaseRegByUser.data.getCaseRegByUser
  const caseRegistrationsInProgress = caseRegistrations.filter((caseReg) => caseReg.document_status_id == 0 || caseReg.document_status_id == 1);
  const caseRegistrationsCompleted = caseRegistrations.filter((caseReg) => caseReg.document_status_id > 1);

  return (
    <AppBar style={{ background: '#0fa7cd' }} position="static">
    <div  style={{ paddingLeft: 25, paddingRight: 25 }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/Home">
              <img src={Logo} className="logo" alt="Ethics Commission logo" />
            </Link>
          </Typography>
          
          <Typography variant="h4" className={classes.title}>
            Restricted Developer Filing System
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open menu">
              <IconButton style={{color: "white"}} onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <PersonIcon style={{color: "white"}} /> {userInfo ? `${ userInfo.given_name}` : ""}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px', display: 'flex', alignItems: 'center', textAlign: 'center' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem className={classes.menuItem} component="a" href="https://angelenouat.lacity.org/acctVerification?redirectFrom=%2Fsettings" target="_blank">
                <Avatar /> {userInfo ? `${userInfo.name}` : ""}
              </MenuItem>
              <Divider />
              <MenuItem className={classes.menuItem} component={Link} to="/Register">
                Register New
              </MenuItem>
              {caseRegistrationsInProgress.length > 0 && (
              <MenuItem className={classes.menuItem} component={Link} to="/Registrations/InProgress">
                In Progress
              </MenuItem>)}
              {caseRegistrationsCompleted.length > 0 && (
              <MenuItem className={classes.menuItem} component={Link} to="/Registrations/Complete">
                Completed
              </MenuItem>)}              
              {userInfo.userSecLvl == 'AD' && (
                <>
                <Divider />
                <MenuItem className={classes.menuItem} component={Link} to="/Admin">
                Admin Menu
                </MenuItem></>)}         
              <Divider />
              <MenuItem className={classes.menuItem} onClick={logout}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        </div>
    </AppBar>
    
  );
};
export default Navbar;
