import React, { useState, useEffect, useRef } from "react";
import { getContactByCntctUuid, formatContactName } from "../functions/validation"

import { Controller, useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import TextField from "@material-ui/core/TextField"
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "bootstrap/dist/css/bootstrap.min.css";
import { Box }from '@mui/material/';
import JSONPretty from "react-json-pretty";
import { ErrorMessage, Formik, FieldArray, Form, Field, useFormikContext, FastField, useField } from 'formik';
import * as yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RESET_DOCUMENT_PDF_STATUS, EDIT_CASE_REG, VIEW_CASES, VIEW_REG_CASES } from "../Queries";

import {v4 as uuidv4} from 'uuid';
import { values } from "lodash";

const DisplayContactForm = (props) => {
  const principalOfUuid = props.principalOfUuid ? props.principalOfUuid : "" // if a principal, will pass the principalOfUuid of the organization
  const principalFlg = principalOfUuid != "" ? "Y" : "N"
  const principalOfCoBusnNm = formatContactName(getContactByCntctUuid(props.contacts,principalOfUuid))

  const blankContact = {
    frstNm: '', lstNm: '', coBusnNm: '', phoneNumberFull: '', emailAddrNm: '', strNmFull: '', cityNm: '', stCd: '', zipCd: '', firstName: '', lastName: '', title: '', indOrgFlg: '', principalFlg: principalFlg, title: '', cntctUuid: uuidv4(), principalOfUuid: principalOfUuid, principalOfCoBusnNm: principalOfCoBusnNm
  }
  const contact = props.contact ? props.contact : blankContact

  /* initialize these for older registrations
  contact.indOrgFlg = Boolean(contact.indOrgFlg) ? props.contact.indOrgFlg : ""
  contact.principalFlg = Boolean(contact.principalFlg) ? props.contact.principalFlg : principalFlg
  contact.title = Boolean(contact.title) ? props.contact.title : "" */

  const [contactData, setContactData] = useState(contact);
  const styles = {
    error: {
        color: 'red',
        fontSize: 'small'
    }
};



  const [updCaseReg] = useMutation(EDIT_CASE_REG);
  /* reset PDF status on document to force PDF to re-render */
  const [resetDocumentPDFStatus] = useMutation(RESET_DOCUMENT_PDF_STATUS);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      checkbox: false
    }
  });

  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const contactSchema = yup.object().shape({
    indOrgFlg: yup.string().required("You must select Individual or Business/Organization").nullable(),
    frstNm: yup
      .string()
      .nullable()
      .matches(/^(?=.*\S).+$/, 'Your first name must be 1 or more characters.')
      .when("indOrgFlg", {
        is: (indOrgFlg)=> indOrgFlg === "I",
        then: yup.string().required("Required")
    }),
    lstNm: yup
      .string()
      .nullable()
      .matches(/^(?=.*\S).+$/, 'Your last name must be 1 or more characters')
      .when("indOrgFlg", {
        is: (indOrgFlg)=> indOrgFlg === "I",
        then: yup.string().required("Required")
    }), /* yup.string().required("Required").nullable() */
    coBusnNm: yup
      .string()
      .matches(/^(?=.*\S).+$/, 'Your business name must be 1 or more characters')
      .required("Required")
      .nullable(),
    strNmFull: yup
      .string()
      .matches(/^(?=.*\S).+$/, 'Your street name must be 1 or more characters')
      .required("Required")
      .nullable(),
    cityNm: yup
      .string()
      .matches(/^(?=.*\S).+$/, 'Your city name must be 1 or more characters')
      .required("Required")
      .nullable(),
    stCd: yup
      .string()
      .matches(/^[A-Za-z]+$/, 'Please enter valid name')
      /* .matches(/^[A-Za-z ]*$/, 'Please enter valid name') */
      .min(2, 'Please enter state abbreviation')
      .max(2, 'Please enter no more than two characters')
      .required("Required")
      .nullable(),
    zipCd: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits")
      .required("Required")
      .nullable(),
    title: yup
      .string()
      .nullable()
      .matches(/^(?=.*\S).+$/, 'Your title must be 1 or more characters')
      .when("indOrgFlg", {
        is: (indOrgFlg)=> indOrgFlg === "I",
        then: yup.string().required("Required")
      }), /* yup.string().required("Required").nullable(), */
    phoneNumberFull: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required("Required")
      .nullable(),
    emailAddrNm: yup
      .string()
      .email("Not a valid email")
      .required("Required")
      .nullable()
  });

  const setFalsyToBlank = (thisString) => thisString ? thisString.trim() : ""

  /* useEffect to combine first, last and biz name when organization is chosen.
  useEffect(() => { */
  const handleIndOrdFlgChange = (e, setFieldValue) => {
    const indOrgFlg = e.target.value;
    //console.log('indOrgFlg',indOrgFlg)
    if (indOrgFlg == "O") {
      // if an organization move all name fields into org name.
      const coBusnNm = (setFalsyToBlank(contactData.frstNm) + " " + setFalsyToBlank(contactData.lstNm) + " " + setFalsyToBlank(contactData.coBusnNm)).trim()
      const frstNm = ""
      const lstNm =  ""
      
      // update values in Formik
      setFieldValue('coBusnNm',coBusnNm )
      setFieldValue('frstNm',frstNm )
      setFieldValue('lstNm',lstNm )

      let newContactData = {
        ...contactData,
        indOrgFlg: indOrgFlg,
        coBusnNm: coBusnNm,
        frstNm: frstNm,
        lstNm: lstNm
      }
      // update values in state
      //console.log('handleIndOrdFlgChange',newContactData)
      setContactData(newContactData)

    } else if (contactData.principalFlg =="Y" && indOrgFlg == "I") {
        // if an individual/principal, set the org name to the parent org
        const coBusnNm = contactData.principalOfCoBusnNm

        // update value in Formik
       setFieldValue('coBusnNm',coBusnNm )

        let newContactData = {
          ...contactData, 
          indOrgFlg: indOrgFlg,
          coBusnNm: coBusnNm
        }
        // update values in state
        //console.log('handleIndOrdFlgChange',newContactData)
        setContactData(newContactData)

      } else {

        let newContactData = {
          ...contactData, 
          indOrgFlg: indOrgFlg
        }    
        // update values in state
        //console.log('handleIndOrdFlgChange',newContactData)
        setContactData(newContactData)
      }
      
  
    }
  //}, [contactData.indOrgFlg]);  combine the first, last and biz name if changes

  const handleContactChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newContactData = {
      ...contactData,
      [name]: value
    }
    //console.log('handleContactChange',newContactData)
    setContactData(newContactData)
  };

  {/*const handlePrincipalFlgClick = (e) => {
    setPrincipalFlgChecked(e.target.checked)
    let newContactData = contactData
    newContactData.principalFlg = e.target.checked ? "Y" : "N"
    setContactData(newContactData)
    return newContactData.principalFlg
    
  };
}*/}

  const initialValues =  useRef(contact) // this fixes a Formik bug with validateOnMount useRef(contact)

  return (
      <Formik initialValues={initialValues.current} validationSchema={contactSchema} validateOnMount={true} >
            {({errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
        <Form id="contactForm">
        <Field name="cntctUuid" type="hidden" value={values.cntctUuid} /> {/*unique ID created by Ethics */}
        <Field name="principalFlg" type="hidden" value={Boolean(values.principalOfUuid) ? "Y" : "N"} /> {/* if there is an principalOfUuid passed, it's a principal */}
        <Field name="principalOfUuid" type="hidden" value={values.principalOfUuid} /> {/* if a principal, this links to the org 
        <Field name="principalOfCoBusnNm" type="hidden" value={values.principalOfCoBusnNm} />*/}
        {/* values.principalFlg =="Y" && values.indOrgFlg == "I" && if a principal and Individual,  link to parent org 
            <Field name="coBusnNm" type="hidden" value={values.principalOfCoBusnNm} /> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup 
              id="indOrgFlg" 
              onBlur={(e)=>{handleBlur(e)}}
              row name="indOrgFlg"  
              onChange={(e) => { handleIndOrdFlgChange(e, setFieldValue), handleChange(e)}}
              value={values.indOrgFlg}
              error={errors.indOrgFlg}
              helperText={errors.indOrgFlg}
            >
              <FormControlLabel
                name="indOrgFlg"
                value="I"
                control={<Radio required={true}/>}
                label="Individual"
              />
              <FormControlLabel
                name="indOrgFlg"
                value="O"
                control={<Radio required={true}/>}
                label="Business or Organization"
              />
              {errors.indOrgFlg && <><br /><span style={styles.error}>{errors.indOrgFlg}</span></>}
            </RadioGroup>
          </Grid>

          {values.indOrgFlg == "O" || (
            <>
              <Grid item xs={12} md={6}>
                <Field name="frstNm"
                  placeholder="First Name" variant="outlined" size="small" label="First Name" fullWidth
                  onBlur={(e)=>{handleBlur(e)}}
                  onChange={(e) => { handleContactChange(e), handleChange(e)}}
                  as={TextField} 
                  error={errors.frstNm}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
                  helperText={errors.frstNm}

                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field name="lstNm"
                  placeholder="Last Name" variant="outlined" size="small" label="Last Name" fullWidth
                  onBlur={(e)=>{handleBlur(e)}}
                  onChange={(e) => { handleContactChange(e), handleChange(e)}}
                  as={TextField} 
                  error={errors.lstNm}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
                  helperText={errors.lstNm}

                />
              </Grid>
            </>
          )}
          
          { /* If this is a principal and an Individual, don't show. */  
          (values.principalFlg =="Y" && values.indOrgFlg == "I") ||
          <Grid item xs={12}>
              <Field name="coBusnNm" //value={values.coBusnNm}
                placeholder="Organization" variant="outlined" size="small" label="Organization" fullWidth
                onChange={(e) => { handleContactChange(e), handleChange(e)}}
                as={TextField}
                error={errors.coBusnNm}
                disabled={values.indOrgFlg==""} // disable until Individual/Organization is chosen
                helperText={errors.coBusnNm}

              />
            </Grid>
        }

          {/* If this is a principal and an Organization, edit the org name */
          values.principalFlg =="Y" &&  <Grid item xs={12}>
              <Field name="principalOfCoBusnNm" //value={values.coBusnNm}
                placeholder="Principal of" variant="outlined" size="small" label="Principal of" fullWidth
                onChange={(e) => { handleContactChange(e), handleChange(e)}}
                as={TextField}
                disabled={true}
                helperText={errors.coBusnNm}

              />
            </Grid>
          }

          {/* <ErrorMessage /> */}
          {values.indOrgFlg == "I" && (
            <>
              <Grid item xs={12}>
                <Field name="title" //value={values.title}
                  placeholder="Title" variant="outlined" size="small" label="Title" fullWidth
                  onChange={(e) => { handleContactChange(e), handleChange(e)}}
                  as={TextField}
                  error={errors.title}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
                  helperText={errors.title}
                />
              </Grid>
              {/*<Grid item xs={12}>
              <Tooltip 
                title="What is a Principal?
                A principal includes the following for an applicant or property owner:
                
                -Board chair, President, CEO, CFO, COO or any individual who serves in the functional equivalent of one or more of these positions or;
                -A person who owns 20 percent or more or;
                -A person authorized to represent before the Planning Department concerning the SPE."
              >
              <InfoIcon />
              </Tooltip>

                 
                 <Checkbox
                  name="principalFlg"
                  checked={principalFlgChecked}
                  onClick={(e) => {handlePrincipalFlgClick(e)}}
                  // Doesn't work onChange={(e) => {handleChange(e)}}
                  //onChange={(e) => {handleContactChange(e)}}
                  
                /> 
              </Grid>*/}
            </>
          )}

        {/* If this is a principal, show the Organization they are Principal of. 
        values.principalFlg ==="Y" && 
            <Grid item xs={12}>
              <h5>Principal of {values.principalOfCoBusnNm}</h5>
            </Grid> 
          */}
          <Grid item xs={12} md={6}>
            <Field name="phoneNumberFull" //value={values.phoneNumberFull}
              placeholder="Phone Number" label="Phone Number" size="small" variant="outlined" fullWidth
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={errors.phoneNumberFull}
              disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.phoneNumberFull}

            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="emailAddrNm" //value={values.emailAddrNm}
              placeholder="Email" label="Email" size="small" variant="outlined" fullWidth
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={errors.emailAddrNm}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.emailAddrNm}
            />
          </Grid>
          <Grid item xs={12}>
            <Field name="strNmFull" //value={values.strNmFull}
              placeholder="Street Address" label="Street Address" size="small" variant="outlined" fullWidth
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={Boolean(errors.strNmFull)}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.strNmFull}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="cityNm" //value={values.cityNm}
              placeholder="City" label="City" size="small" variant="outlined" fullWidth
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={errors.cityNm}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.cityNm}
            />
          </Grid>
          <Grid item xs={12} md={2}>
          <Field name="stCd" //value={values.stCd}
              placeholder="State" maxLength="2" label="State" size="small" variant="outlined" fullWidth
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={errors.stCd}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.stCd}
            />
          {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select 
              labelId="countrySelectLabel" 
              id="countrySelect" 
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              error={errors.stCd}
              helperText={errors.stCd}
              value={state}
              >
              {states?.map(option => {
                    return (
                      <MenuItem key={option.value} value={option.value} label="State">
                        {option.names ?? option.value}
                      </MenuItem>
                    );
                })}
            </Select>
          </FormControl> */}
           
          </Grid>
          <Grid item xs={12} md={4}>
            <Field name="zipCd" //value={values.zipCd}
              placeholder="Zipcode" label="Zipcode" size="small" fullWidth variant="outlined"
              onChange={(e) => { handleContactChange(e) , handleChange(e)}}
              as={TextField}
              error={errors.zipCd}
                  disabled={values.indOrgFlg===""} // disable until Individual/Organization is chosen
              helperText={errors.zipCd}
            />
          </Grid>
          {/* If an organization (not a principal), display message s*/
            values.indOrgFlg == "O" && values.principalFlg != "Y" &&           
          <Grid item xs={12}>
            <div align="left">
            <p>Organizations are required by law to also report all principals.</p>
            <p>A principal includes the following:</p>
            <ul>
            <li>Board chair, President, CEO, CFO, COO or any individual who serves in the functional equivalent of one or more of these positions or;</li>
            <li>A person who owns 20 percent or more or;</li>
            <li>A person authorized to represent before the Planning Department concerning the SPE.</li>
            </ul>
            <p>To report principals, click "{props.saveBtnText}" button below, then click "Add Principals".</p>
            </div>
          </Grid>}
          <br />
        </Grid>
        <br />
        {/* Show disable button */}
        {Object.keys(errors).length > 0 &&(
          <button 
          variant='contained' 
          disabled={true}
          className={"btn btn-primary disabled"}>
          {props.saveBtnText}
        </button>
        )}
        {Object.keys(errors).length === 0 && (
          <button 
          variant='contained' 
          disabled={false}
          type="button"
          onClick={(e) => {
              props.handleSave(contactData, props.index)
              setContactData(blankContact)
          }}
          className={"btn btn-primary active"}>
          {props.saveBtnText}
        </button>
        )}
        {/* <button 
          variant='contained' 
          disabled={}
          onClick={() => {
            props.handleSave(values, props.index)
            setContactData(blankContact)
          }}
          className={Object.keys(errors).length === 0 ? "btn btn-primary disabled" : "btn btn-primary active"}>
          {props.saveBtnText}
        </button> 
        <p>principalFlg: {values.principalFlg}, cntctUuid: {values.cntctUuid}, principalOfUuid: {values.principalOfUuid}</p>*/}
        {/*<JSONPretty data ={errors} />
        <JSONPretty data ={touched} />
        <JSONPretty data={values} />*/}
        </Form>
          )}
          </Formik>
  )

}

export default DisplayContactForm