import React, { useState } from "react";
import "../styles.css";
import {caseRegCompleted} from "../functions/validation"

import DisplayCaseDataTable from "./DisplayCaseDataTable";
import "bootstrap/dist/css/bootstrap.min.css";
import JSONPretty from "react-json-pretty";
import Moment from 'react-moment';

import { useHistory } from 'react-router-dom';

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Review(props) {
  const registrationCompleted = caseRegCompleted(props.data)

  return (
    <>
      <h4>Please review the data you have entered.</h4>
      {registrationCompleted ? <> 
      <p style={{color: "green", textAlign: 'center'}}>Your registration form is complete.</p> 
      <p>To complete the registration process, click "SIGN" to sign your registration.</p>
      </>
      : <> 
      <p style={{color: "red", textAlign: 'center'}}>Your registration form is incomplete.</p>
      <p>To update your registration, click "BACK" or on any section of the registration below. The "SIGN" button will remain disabled until the registration form is complete.</p>
      </> 
      }
      {props.data && <DisplayCaseDataTable caseData={props.data} showErrColumn={true} />}
    </>
  );
}

export default Review;