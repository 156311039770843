import React from "react";
import "../styles.css";
import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent, CardActions} from '@mui/material/';
import {Box, Button, Grid, Typography} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import _ from "lodash";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};
const AdminMenu = () => {
    return (
    <div>
        <h4>Administration</h4>

        <Box alignItems="center" justifyContent="center">    
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image="/img/checklist.jpg"
                alt="Compliance report"
                title="Compliance report"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Compliance Report
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Generate report of planning cases that should register and registration status.
                </Typography>
              </CardContent>
              <CardActions>
              <Button
                className="yellowButton"
                style={{"margin":"auto"}}
                component={Link}
                to="/Admin/compliance-report"
                value="compliance"
                >Go</Button>
              </CardActions>
            </Card>
          </Grid>

      <Grid item xs={12} sm={6}>
        <Card>
          <CardMedia
            component="img"
            height="300"
            image="/img/users.jpg"
            alt="group of users"
            title="Users on phones"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              User Maintenance
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Set user security level to administrator.
            </Typography>
          </CardContent>
          <CardActions>
          <Button
            className="yellowButton"
            component={Link}
            to="/Admin/user-maintenance"
            value="user maintenance" 
            style={{"margin":"auto"}}
            >Go</Button>
          </CardActions>
        </Card>
      </Grid>

    </Grid>
        </Box>
                    <br />
                    <br />
        <Button
              className="yellowButton"
              //onClick={() => setSelectedOption('Home')}
              component={Link} to={`/Home`}
              sx={{ mr: 1 }}
              value="home" 
          >
          <HomeIcon /> Main Menu
          </Button>
            </div>

)
    
}

export default AdminMenu