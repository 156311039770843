import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Spinner } from 'reactstrap';

export default function Pdf(props) {
    console.log('Pdf props',props)
    const [PDFUrl, setPDFUrl] = useState(null);

    const documentId = props.documentId
    const userId = props.userId
    const documentSigned = props.documentSigned
    const createPDFUrl = `${process.env.REACT_APP_WWWROOT}/cfcs/pdf/ajaxrequesthandler.cfc?method=createpdf&document_id=${documentId}&user_id=${userId}&documentSigned=${documentSigned}`
    
    useEffect(async () => {
        setPDFUrl(null)
        //console.log('createPDFUrl', createPDFUrl)
        
        const result = await axios(createPDFUrl);
        //console.log('Pdf,result', result)
        const data = result.data.data[0]
        //console.log('Pdf,data', data)
    
        setPDFUrl( `${process.env.REACT_APP_WWWROOT}${data.internal_pdf_path}${data.filename}?${Date.now()}`);
      }, [createPDFUrl]);

    return (
        <>
        {PDFUrl ? <div className="embed-responsive embed-responsive-16by9" style={{height: '100vh'}}>
            <embed className="embed-responsive-item" src={PDFUrl}
            style={{width: '100%', height: '100%', display: 'block'}} allowFullScreen></embed> 
        </div>
        :
        <Spinner color="dark" />
        }
        </>
    );
}
