import React from 'react'
import * as yup from 'yup';

const required = "* Required"
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const UserValidation = yup.object().shape({
    frstNm: yup.string().required(required),
    lstNm: yup.string().required(required),
    coBusnNm: yup.string().required(required),
    phoneNumberFull: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(required),
    emailAddrNm: yup.string().email("Not a valid email").required(required),
    strNmFull: yup.string().required(required),
    cityNm: yup.string().required(required),
    stCd: yup.string().required(required),
    zipCd: yup.string().required(required),/*
    title: yup.string().required(),
    indOrgFlg: yup.string().required(), */
  });

export default UserValidation

