import React from 'react'
import MUIDataTable from "mui-datatables";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Container, Paper } from "@material-ui/core/";
import { GET_USER_MAINTENANCE, EDIT_USER_SEC_LVL } from "../Queries";
import { Spinner } from 'reactstrap';
import { ConstructionOutlined } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const userMaintenance = () => {
  const [editSecLvl, { loading, error }] = useMutation(EDIT_USER_SEC_LVL)

  const getAllUsers = useQuery(GET_USER_MAINTENANCE);
  //const getCases = useQuery(GET_CASES);
  if (getAllUsers.loading) return <Spinner color="dark" />;
  if (getAllUsers.error) return <React.Fragment>Error :(</React.Fragment>;
  const displayUsers = getAllUsers.data.getUserMaintenance;

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const columns = [
      {
        name: "USER_ID",
        label: "User",
        options: {
        filter: false,
        sort: true,
        }
      },
      {
          name: "USER_FNM",
          label: "First Name",
          options: {
          filter: false,
            sort: true,
          }
          },
          {
          name: "USER_LNM",
          label: "Last Name",
          options: {
            display: true,
            filter: false,
            sort: false
          }
          },
      {
        name: "USER_SEC_LVL",
        label: "Security Level",
        options: {
        filter: false,
        display: true,
        sort: false,
        }
      },
      {
          name: "USER_RDFS_ACCESS",
          label: "RDFS Access",
          options: {
          display: false,
          filter: false,
          }
      },
      {
        name: "Administrator",
        options: {
          filter: false,
          sort: false,
          empty: true,
          download: true,
          customBodyRenderLite: (dataIndex) => {
            let user = getAllUsers.data.getUserMaintenance[dataIndex]
            let userEmail = user.USER_EMAIL
            let isCityEmail = userEmail.split("@")[1].trim() == 'lacity.org'
            if (isCityEmail) {
              return (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Switch 
                    onClick={(e) => {
                      let userSecLvl = e.target.checked ? "AD" :"US"
                      getAllUsers.data.getUserMaintenance[dataIndex].USER_SEC_LVL = userSecLvl
                      editSecLvl({ variables: {USER_EMAIL: `${userEmail}`, USER_SEC_LVL: `${userSecLvl}`}})
                    }
                  }
                  checked={user.USER_SEC_LVL == "AD"}
                  {...label}  
                    
                  />
                </Stack>
              )
            } else {
              return (<div>N/A</div>)
            }
          }
        }
      },
      ];
      
      
      const options = {
        filterType: 'dropdown',
        rowsPerPage: 5,
        rowsPerPageOptions: [10, 15, 20],
        selectableRows: false
      };

  return (
    <MUIDataTable 
        title={"User Report"}
        data={displayUsers}
        columns={columns}
        options={options}
    >
    </MUIDataTable>
  )
}

export default userMaintenance