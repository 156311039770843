import React, { useContext } from "react";
import { UserContext } from "../App";
import "../styles.css";
import {caseRegCompleted} from "../functions/validation"
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams, Link } from 'react-router-dom';
import {Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TablePagination, Typography} from '@mui/material';
import { Icon } from '@iconify/react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { VIEW_REG_CASES_BY_USER, AMEND_CASE_REG, CANCEL_DOCUMENT } from "../Queries";
import { Spinner } from 'reactstrap';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import HomeIcon from '@mui/icons-material/Home';
import LastPageIcon from '@mui/icons-material/LastPage'
import _ from "lodash";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};
const MainMenu = () => {

    // keep our place vis-a-vis url parameters
    const params=useParams();
    const status = params.status ? params.status : "InProgress"

    const history = useHistory();
    const [cancelDocument] = useMutation(CANCEL_DOCUMENT);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [cancelDocumentID, setCancelDocumentID] = React.useState(null);

    const userInfo = useContext(UserContext)

    const [amendCaseReg] = useMutation(AMEND_CASE_REG);

    /* Grab all Registrations related to this user */
    const userEmail = Boolean(userInfo) ? userInfo.email : null
    const { data, loading, error, refetch } = useQuery(VIEW_REG_CASES_BY_USER, { variables: {userEmail: userEmail}, fetchPolicy: 'network-only'})
    if (!userInfo || loading ){
      return <Spinner color="dark" />; // show spinner while loading.
    }
  
    if (error){
      console.error(`Retrieve error! ${error}`)
      return <React.Fragment>Error :( </React.Fragment>;
    }

    const caseRegistrations =  data.getCaseRegByUser 
    const caseRegistrationsInProgress = caseRegistrations.filter((caseReg) => caseReg.document_status_id == 0 || caseReg.document_status_id == 1);
    const caseRegistrationsCompleted = caseRegistrations.filter((caseReg) => caseReg.document_status_id > 1);

   /* Table Pagination stuff */

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

   function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
      };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

    const handleEditReg = (caseId) => {
        sessionStorage.setItem("caseId", caseId)
        // Database doesn't always query after this
        // Sometimes redirects to http://localhost:3000/Registrations/Register/caseId/Description
        history.push({pathname: `/Register/${caseId}/Description`}); 
    }
    const handleAmendReg = (caseId) => {
        sessionStorage.setItem("caseId", caseId)
        amendCaseReg({ variables: { 
            caseID: `${caseId}`, 
            userEmail: userInfo.email,
            userFrstNm: userInfo.given_name,
            userLstNm: userInfo.family_name
          } });
        history.push({pathname: `/Amend/${caseId}/Description`}); 
    }
    
    const handleEsignReg = (caseId) => {
      let url = `/Esign/${caseId}`
      history.push(url)
    }
    
    const handleCancelReg = (document_id) => {
      let resp = cancelDocument({ variables: { 
          document_id: document_id, 
          userEmail: userInfo.email
        }});
      setCancelDocumentID(null);
      history.push({pathname: `/Registrations/${status}`}); 
  }
  const handleCancelClose = () => {
    setCancelDocumentID(null);
  };

    const showRegStatus = (caseReg) => {  
        const documentStatusId = caseReg.document_status_id
        const registrationComplete = caseRegCompleted(caseReg.caseRegistrationData)
        switch(documentStatusId) {
            case -2:
                return "Amended"
            case -1:
                return "Cancelled"
            case 0:
                return registrationComplete ? "Complete, Requires Signature": "Incomplete"
            case 1:
                return registrationComplete ? "Complete, Requires Signature": "Incomplete"
            case 2:
                return "Completed and Signed"
            case 3:
                return "Completed and Signed"
            default:
                return "Not Started"
          }
    }

    /* display a table of case registrations */
    const displayCaseRegistrations = (caseRegistrations) => {
      const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - caseRegistrations.length) : 0;
      return(
        <>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label={`table`}>
            <TableHead>
            <TableRow>
                <TableCell align="left"><strong>Case Number</strong></TableCell>
                <TableCell align="left"><strong>Project Address</strong></TableCell>
                <TableCell align="left"><strong>Status</strong></TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {(rowsPerPage > 0
                ? caseRegistrations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : caseRegistrations).map((caseReg,index) => (
                <>
                <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell align="left" key={`${index}.1`}>{caseReg.caseRegistrationData.caseNbr}</TableCell>
                <TableCell align="left" key={`${index}.2`}>{caseReg.caseRegistrationData.primaryAddress}</TableCell>   
                <TableCell align="left" key={`${index}.3`} style={{color: caseReg.document_status_id == 3 ? "green": "red"}}>
                {caseReg.amendNum > 0 ? `Amendment ${caseReg.amendNum} ` : "Registration "}
                {showRegStatus(caseReg)}
                </TableCell>         
                <TableCell align="right"  key={`${index}.4`}> 
                {
                    caseReg.document_status_id  > 1 ? 
                        <>
                            {/*<Button key={`${index}.4.1`} onClick={() => handleViewReg(caseReg.document_id)}>*/}
                            <Button key={`${index}.4.1`} component={Link} to={`/View/${caseReg.document_id}`}>
                                <Icon icon="ep:document" width={36} /> View
                            </Button>
                            <Button key={`${index}.4.2`} onClick={() => handleAmendReg(caseReg.caseID)}>
                                <Icon icon="fluent:copy-add-24-regular" width={36}  /> Amend
                            </Button>
                            
                        </> 
                    : caseReg.document_status_id >= 0 ? 
                        <>
                            <Button key={`${index}.4.1`} onClick={() => handleEditReg(caseReg.caseID)} >
                                <Icon icon="clarity:note-edit-solid" width={36} /> {caseRegCompleted(caseReg.caseRegistrationData) ? "Edit" : "Complete"}
                            </Button>
                            {caseRegCompleted(caseReg.caseRegistrationData) && 
                            <Button key={`${index}.4.2`} onClick={() => handleEsignReg(caseReg.caseID)}  >
                              <Icon icon="fa6-solid:file-signature" width={36} /> Sign
                            </Button>}
                            <Button key={`${index}.4.3`} onClick={() => setCancelDocumentID(caseReg.document_id)}  >
                              <Icon icon="icons8:cancel" width={36} /> Cancel
                            </Button>
                        </>
                    : 
                        ""
                }
                </TableCell>
                </TableRow>
                </>
            ))}
            {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>     
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={caseRegistrations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
        </Table>
        </TableContainer>
        {/* Modal for canceling registration */}
        <Modal
            open={Boolean(cancelDocumentID)}
            onClose={handleCancelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to cancel?
              <br />
              <button 
              variant='contained'
              onClick={() =>{handleCancelReg(cancelDocumentID)}} 
              className="button">
                  Yes
              </button>
              <button variant='contained' onClick={handleCancelClose} className="button">No</button>
          </Typography>
          </Box>
        </Modal>
        </>
      )
    }
    

    // Eric rather have the user go straight to the homepage regardless of cases being registered or not
    const caseRegDisplay = 
            <div>
            {status == 'InProgress' && (
                <>
                    <h4>Registrations In Progress</h4>
                    <Button
                        className="yellowButton"
                        //onClick={() => setSelectedOption('Home')}
                        component={Link} to={`/Home`}
                        sx={{ mr: 1 }}
                        value="home" 
                    >
                    <HomeIcon /> Main Menu
                    </Button>
                    <br />
                    <br />
                    {caseRegistrationsInProgress.length > 0 ? 
                    displayCaseRegistrations(caseRegistrationsInProgress) :
                    "There are no registrations in progress."}
                </>
            )}

            {status == 'Complete' && (
                <>
                    <h4>Registrations Completed</h4>
                    <Button
                        className="yellowButton"
                        //onClick={() => setSelectedOption('Home')}
                        component={Link} to={`/Home`}
                        sx={{ mr: 1 }}
                        value="home" 
                    >
                        <HomeIcon /> Main Menu
                    </Button>
                    <br />
                    <br />
                    {caseRegistrationsCompleted.length > 0 ? 
                    displayCaseRegistrations(caseRegistrationsCompleted) :
                    "There are no completed registrations."
                    }
                </>
                )}
        </div>

 return (caseRegDisplay)
    
}

export default MainMenu