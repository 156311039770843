/*libraries*/
import React, { useContext, useState } from "react";
import _ from "lodash";
import JSONPretty from "react-json-pretty";
import { Box, Button, Card, Grid, Stepper, Step, StepLabel, Typography, BottomNavigation } from '@mui/material';


/*components*/
import DisplayContactGrid from "./DisplayContactGrid";

const Applicants = (props) => {
    const applicants = props.applicants;
    
    const handleChanges = (contacts) => {
        props.setFieldValue('applicants', contacts); // save to Formik values on Register.js
        props.values.applicants = contacts // update local copy of values with updates
        props.handleSave(props.values) // save to the database or Register.js
        //console.log('Applicants/handleChanges/contacts',contacts)
    }
    return (
        <>
            <div className="form-group container">
            <h4>Applicants</h4>
            {applicants && <>
            <DisplayContactGrid contacts={applicants} handleChanges={handleChanges}  name="applicants" required={true} />
            </>}
            </div>
            <br /><br />
            <p>You are adding to the Applicants section. To add Owners go to the next section. If Applicant and Owner are the same please input in both sections.</p>
        </>
    );
}

export default Applicants