import { gql } from 'apollo-boost';

export const GET_CASES = gql`
  {
    getCases {
      caseID,
      caseData
    }
  }
`;

export const VIEW_CASES = gql`
  query ($caseID: String){
    getCaseInfo(caseID: $caseID) {
      caseID
      caseData
      SPEFlg
    }
  }
`;

export const VIEW_REGISTRANT_INFO = gql`
  query ($userEmail:[String]){
    getRegistrantInfo(userEmail: $userEmail){
      user_id
      user_fnm
      user_lnm
    }
  }
`

export const VIEW_REG_CASES = gql`
  query ($caseID: String){
    getCaseRegInfo(caseID: $caseID) {
      caseID,
      caseRegistrationData,
      document_id,
      document_status_id,
      amendNum,
      filer_fullname,
      user_id,
      filer_lname,
      filer_fname
    }
  }
`;

export const VIEW_REG_CASES_BY_USER = gql`
  query ($userEmail: String){
    getCaseRegByUser(userEmail: $userEmail) {
      caseID,
      caseRegistrationData,
      document_id,
      document_status_id,
      amendNum
    }
  }
`;

export const ADD_CASE = gql`
  mutation ($caseID: String, $caseData: JSON) {
    createCaseInfo (caseID: $caseID, caseData: $caseData){
      caseID,
      caseData
    }
  }
`;

export const EDIT_CASE = gql`
  mutation($caseID: String, $caseData: JSON) {
    updateCaseInfo (caseID: $caseID, caseData: $caseData)
  }
`;

export const DELETE_CASE = gql`
  mutation($caseID: String) {
    deleteCaseInfo(caseID: $caseID)
  }
`;

export const ADD_CASE_REG = gql`
  mutation ($caseID: String, $caseRegistrationData: JSON, $userEmail: String, $userFrstNm: String, $userLstNm: String) {
    createCaseRegInfo (caseID: $caseID, caseRegistrationData: $caseRegistrationData, userEmail: $userEmail, userFrstNm: $userFrstNm, userLstNm: $userLstNm){
      caseID,
      caseRegistrationData,
      userEmail, 
      userFrstNm, 
      userLstNm
    }
  }
`;

export const EDIT_CASE_REG = gql`
  mutation($caseID: String, $amendNum: Int, $caseRegistrationData: JSON) {
    updateCaseRegInfo (caseID: $caseID, amendNum: $amendNum, caseRegistrationData: $caseRegistrationData){
      caseID,
      amendNum,
      caseRegistrationData
    }
  }
`;

export const AMEND_CASE_REG = gql`
  mutation ($caseID: String, $userEmail: String, $userFrstNm: String, $userLstNm: String) {
    amendCaseRegInfo (caseID: $caseID, userEmail: $userEmail, userFrstNm: $userFrstNm, userLstNm: $userLstNm){
      caseID,
      userEmail, 
      userFrstNm, 
      userLstNm
    }
  }
`;

export const UPSERT_RDFS_USER = gql`
  mutation ($userEmail: String, $userFrstNm: String, $userLstNm: String) {
    upsertRdfsUser(userEmail: $userEmail, userFrstNm: $userFrstNm, userLstNm: $userLstNm){
      userEmail, 
      userFrstNm, 
      userLstNm
    }
  }
`;

export const RESET_DOCUMENT_PDF_STATUS = gql`
  mutation($document_id: Int) {
    resetDocumentPDFStatus (document_id: $document_id){
      document_id
    }
  }
`;

export const ESIGN_DOCUMENT = gql`
  mutation($document_id: Int, $userEmail: String) {
    esignDocument (document_id: $document_id, userEmail: $userEmail){
      document_id,
      userEmail
    }
  }
`;

export const CANCEL_DOCUMENT = gql`
  mutation($document_id: Int, $userEmail: String) {
    cancelDocument (document_id: $document_id, userEmail: $userEmail){
      document_id,
      userEmail
    }
  }
`;

export const GET_USER_INFO = gql` 
  query ($userEmail: String){
    getUserInfo (userEmail: $userEmail) {
      userEmail,
      userFrstNm,
      userLstNm,
      userSecLvl
    }
  }
`;

export const GET_REGISTRANT_INFO = gql` 
  query ($userEmail: String){
    getUserInfo (userEmail: $userEmail) {
      user_id,
      user_fnm,
      user_lnm
    }
  }
`;

export const GET_CASE_REG_COMPLIANCE = gql`
query GetCaseRegCompliance {
  getCaseRegCompliance {
    compressedData
  }
}
`;

export const GET_USER_MAINTENANCE = gql`
  {
    getUserMaintenance {
      USER_EMAIL
      USER_FNM
      USER_LNM
      USER_SEC_LVL
      USER_RDFS_ACCESS
      USER_ID
    }
  }
`;

export const EDIT_REGISTRANT = gql`
mutation($USER_ID: String, $FILER_FNAME: String, $FILER_LNAME: String , $DOCUMENT_ID: Int){
  updateRegistrantInfo (USER_ID: $USER_ID, FILER_FNAME: $FILER_FNAME, FILER_LNAME: $FILER_LNAME, DOCUMENT_ID: $DOCUMENT_ID){
    USER_ID
    DOCUMENT_ID
    FILER_LNAME
    FILER_FNAME
  }
}`

export const EDIT_USER_SEC_LVL = gql`
  mutation($USER_EMAIL: String, $USER_SEC_LVL: String) {
    updateUserMaintenance (USER_EMAIL: $USER_EMAIL, USER_SEC_LVL: $USER_SEC_LVL){
      USER_EMAIL
      USER_SEC_LVL
    }
  }
`;