import React, { useState, useContext } from 'react';
import { UserContext } from "./App";
import "./styles.css";
//import { useOktaAuth } from '@okta/okta-react';
import { Spinner } from 'reactstrap';
import { useHistory, Redirect } from 'react-router-dom';
import axios from "axios";
import Pdf from './Pdf'
import { Box, Button, Card, CardHeader, CardMedia, CardContent, CardActions, Checkbox, FormGroup, FormControlLabel, Grid, Typography} from '@mui/material/';
import ApprovalIcon from '@mui/icons-material/Approval';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { VIEW_REG_CASES } from "./Queries";
import { useParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import HomeIcon from '@mui/icons-material/Home';
import JSONPretty from 'react-json-pretty';
import { Alert } from 'react-bootstrap';

 export default function Esign(props) {
    const userInfo = useContext(UserContext)
    const userId = userInfo ? userInfo.email : null
    const params=useParams();

    const [documentSigned, setDocumentSigned] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isYellow, setIsYellow] = useState(false);
    const [showError, setShowError] = useState("");
  

    /* Get case data */
    const caseId = Boolean(params.caseId) ?  params.caseId : props.caseId; // caseId can be passed by url or by props
    const viewCaseReg = useQuery(VIEW_REG_CASES, {variables: {caseID: `${caseId}`}, fetchPolicy: 'network-only',
      onCompleted: (data) => setDocumentSigned(Boolean(data.getCaseRegInfo.document_status_id > 1))
    })

    if (viewCaseReg.loading){
      //console.log('loading case reg')
      return <Spinner color="dark" />  // show spinner while loading.
    }
  
    if (viewCaseReg.error){
      console.log(`Retrieve error! ${viewCaseReg.error}`)
      return <React.Fragment>Error :( </React.Fragment>;
    }
    let history = useHistory()
    //console.log('viewCaseReg', viewCaseReg)
    let documentId = viewCaseReg.data.getCaseRegInfo.document_id
    let documentStatusId = viewCaseReg.data.getCaseRegInfo.document_status_id
    let amendNum = viewCaseReg.data.getCaseRegInfo.amendNum
    //let caseRegData = JSON.parse(viewCaseReg.data.getCaseRegInfo.caseRegistrationData)
    let caseRegData = viewCaseReg.data.getCaseRegInfo.caseRegistrationData
    //console.log('documentSigned',documentSigned)

    const receiptUri = `${process.env.REACT_APP_WWWROOT}/CFCs/PDF/pdfViewer.cfm?document_id=${documentId}&publiccopy=no&documentSigned=${documentSigned}`

    const esignText = "By checking this box, I certify under penalty of perjury under the laws of the City of Los Angeles and the State of California that the information submitted in this registration is true and complete and that I understand, will comply with, and will notify all owners and principals of the restrictions against campaign contributions in Los Angeles Municipal Code §§ 49.7.37(B) and (C).  I understand that, if the information in this registration changes, I must amend this registration within ten business days."

    const handleCheckbox = (e) => {
      let isChecked = e.target.checked
      setButtonDisabled(isChecked ? false : true)
      setIsYellow(isChecked);
    }
    
    const handleNewReg = () => {
      history.push({pathname: `/Register`});  
    }
    const handleMainMenu= () => {
      history.push({pathname: `/`});  
    }
    /* Call API to run CFC to sign document. Need to add handling of errors, etc. */
    const handleSignature = async () => {

      setButtonDisabled(true);
      setIsYellow(false);
      

      let esignURL = `${process.env.REACT_APP_WWWROOT}/cfcs/esign.cfc?method=signdocumentreact&document_id=${documentId}&user_id=${userInfo.email}`;

      

      const getData = async () => {
        const response = await axios.get(esignURL);
        const data = response.data

        console.log(esignURL)
        console.log(data)

        console.log(`DATA: ${JSON.stringify(data, null, 2)}`)

        if (data.document_status_id == "3"){
          setDocumentSigned(true)
        }
        else{
          // Add error handeling here.
          setDocumentSigned(false)
          // show the error message for 4 seconds
          setShowError(data.error);
          setButtonDisabled(false);
          setIsYellow(true);
                 
        }
        
        return data 
        }

      getData();
    }
    return ( 
    documentId &&  userId &&   
    <>
        {
        documentSigned ? 
            <Typography sx={{ mt: 2, mb: 1 }}>
              <h4>Your {amendNum > 0 ? "amendment" : "registration"} is complete.</h4>
              <p>You may save or print a copy of the signed form using the icons on the right of the header below.</p>
              <p>Confirmation of this registration will be sent to you by email and is required to complete your application with the Planning Department.</p>
              <p>Because this case is a significant planning entitlement, every person identified in this registration is prohibited from making certain campaign contributions from the date the application was filed with the Planning Department until 12 months after the date a letter of determination is issued or, if no letter is issued, the date the decision on the application is final.  For guidance, please contact the Ethics Commission at (213) 978-1960 or <a href="mailto:ethics.rdfs@lacity.org" target="_blank">ethics.rdfs@lacity.org</a>.</p>
            </Typography>
         : <h4>Please review the filing and click below to sign and submit it.</h4>}
         {documentId && userId && <Pdf documentId={documentId} userId={userId} documentSigned={documentSigned}/>}
        {/* If documentID has been created, then show PDF, otherwise error 
        documentId ? 
          <div className="embed-responsive embed-responsive-16by9" style={{height: '100vh'}}>
              <embed className="embed-responsive-item" src={receiptUri}
              style={{width: '100%', height: '100%', display: 'block'}} allowFullScreen></embed> 
          </div>:
          <p>ERROR: Document not found.</p>*/
        }
              
        {/* If document is unsigned, show signature form */
        (!documentSigned) && (
        <Card  variant="outlined">
          <FormControlLabel sx={{textAlign:"left"}} control={<Checkbox size="large" onChange={handleCheckbox} />} label={esignText} />
          
          <Button disabled={buttonDisabled} onClick={handleSignature} className={isYellow ? 'yellowButton' : 'greyButton'} ><ApprovalIcon />Sign it</Button>
        </Card>
        )}
        {!documentSigned && showError && (
          <>
            <Alert variant="danger" onClose={() => setShowError(false)} dismissible small>
              {showError}
            </Alert>
            
            
          </>
      )}

        
        
        
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button onClick={handleNewReg} className="yellowButton">Register Another Case</Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={handleMainMenu} className="yellowButton"><HomeIcon /> Main Menu</Button>
        </Box>
    </>
    )
  }