import React from "react";
import { Field } from 'formik';
import "bootstrap/dist/css/bootstrap.min.css";
import {TextField} from '@mui/material';



function Description (props) {
    
    return (
        <>
        <div>
            
        <h4>Project Description</h4>
            <div className="form-group">
                <Field 
                    name="projectDescription" 
                    label="Project Description" 
                    as={TextField} 
                    multiline rows={4} 
                    fullWidth
                    /* onChange={handleChange} */
                    onBlur={props.handleBlur}
                    error={props.touched.projectDescription && Boolean(props.errors.projectDescription)}
                    helperText={props.touched.projectDescription && props.errors.projectDescription}
                />
                {/* {props.errors.projectDescription && props.touched.projectDescription ? (
                    <div className="form-error">{props.errors.projectDescription}</div>
                  ) : null} */}
            </div>
            <br/>
            <div className="form-group">
                <Field 
                    name="requestedEntitlement"
                    label="Requested Entitlement" 
                    as={TextField} 
                    multiline rows={4} 
                    fullWidth
                    onBlur={props.handleBlur}
                    error={props.touched.requestedEntitlement && Boolean(props.errors.requestedEntitlement)}
                    helperText={props.touched.requestedEntitlement && props.errors.requestedEntitlement}
                />
            </div>
        </div>
        </>
    );
};

export default Description;