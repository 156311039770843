import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "./App";
import Pdf from './Pdf'
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { Box, Button,  Typography} from '@mui/material/';
import { useParams } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';

export default function View(props) {
    const params=useParams();
    const userInfo = useContext(UserContext)
    const userId = userInfo ? userInfo.email : null

    /* Get case data */
    const documentId = Boolean(params.documentId) ?  params.documentId : props.documentId; // documentId can be passed by url or by props

    
    let history = useHistory()
    
    const handleMainMenu= () => {
      history.push({pathname: `/`});  
      /*<Redirect to="/" />*/
    }

    return ( 
    documentId &&     
    <>
        <Typography sx={{ mt: 2, mb: 1 }}>
            Save or print a copy of the signed form below.
        </Typography>
        <Box sx={{ margin: "auto" }}>
            <Button onClick={handleMainMenu} className="yellowButton"><HomeIcon /> Main Menu</Button>
        </Box>
        {documentId && userId && 
        <Pdf documentId={documentId} userId={userId} />}
        {/*<div className="embed-responsive embed-responsive-16by9" style={{height: '100vh'}}>
            <embed className="embed-responsive-item" src={receiptUri}
            style={{width: '100%', height: '100%', display: 'block'}} allowFullScreen></embed> 
        </div>*/}
    </>
    )
  }