import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import App from "./App";
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import config from "./config";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import Navbar from './components/Navbar';
import awsmobile from './aws-exports'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
  dsn: "https://4ed1a6ff40b94a18ba72a54a7180a44d@o406326.ingest.sentry.io/6463377",
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
}


/*causing cors errors. turned off for now.
Sentry.init({
  dsn: "https://4ed1a6ff40b94a18ba72a54a7180a44d@o406326.ingest.sentry.io/6463377",
  integrations: [new BrowserTracing({
    tracingOrigins: [
        process.env.REACT_APP_APOLLO_CLIENT,
        process.env.REACT_APP_WWWROOT,
        'http://localhost:3000'
      ],
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

// use Amplify endpoint if exists, otherwise pull from environment variable.
const apolloUri = Boolean(awsmobile.aws_cloud_logic_custom) ?  `${awsmobile.aws_cloud_logic_custom[0].endpoint}/graphql` : process.env.REACT_APP_APOLLO_CLIENT; /* */

//const apolloUri = Boolean(process.env.REACT_APP_LOCAL) ? process.env.REACT_APP_APOLLO_CLIENT : `${awsmobile.aws_cloud_logic_custom[0].endpoint}/graphql`


const client = new ApolloClient({
  //uri: `${process.env.REACT_APP_APOLLO_CLIENT}`
  //uri: `${awsmobile.aws_cloud_logic_custom[0].endpoint}/graphql`
  uri: apolloUri
});
const oktaAuth = new OktaAuth(config.oidc);
import { useOktaAuth } from '@okta/okta-react';
import { LocalDining } from "@mui/icons-material";

function GetRoot(){
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
  const [ locationKeys, setLocationKeys ] = useState([])
 
  const history = useHistory(); // example from react-router
  const location = useLocation();
  
  const homeDir = process.env.REACT_APP_HOME_DIR || "/";

  const triggerLogin = async () => {
    //console.log("Sign in Redirect", oktaAuth.signInWithRedirect())
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    //console.log(_oktaAuth);
    //console.log("OriginalUri: ", originalUri);
    if (originalUri == undefined){
      history.replace(
        {
          pathname: toRelativeUrl('/', window.location.origin)
      });
    }
    else {
      history.replace(
        {
          pathname: toRelativeUrl(originalUri[0]),
          state: originalUri[1]
      });
    }
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      //console.log("PreviousAuthState: ", previousAuthState)
      // App initialization stage
      await triggerLogin();
    } else {
      //console.log(true)
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  }

  /* useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
  
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
  
          // Handle forward event
         console.log("Forward")
  
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
  
          // Handle back event
          console.log("Backward")
         
        }
      }
    })
  }, [ locationKeys, ])

  const changePage = (path) => { 
    alert(path)
    history.push({ pathname: path }) 

  }

  window.addEventListener('popstate', (event) => {
    alert("You message");
    changePage()
  }); */

  /* window.addEventListener('pushstate', (event) => {
    alert("Forward");
  }); */

  return (
    <Security 
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler} 
      restoreOriginalUri={restoreOriginalUri}
    >
      <App />
    </Security>
  
  )
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router basename={config.app.basename}>
    <ApolloProvider client={client}>
      <GetRoot />
    </ApolloProvider>
  </Router>,
  rootElement,
);
