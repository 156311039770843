import React from "react";
import _ from "lodash";
import DisplayContactGrid from "./DisplayContactGrid";
import JSONPretty from "react-json-pretty";
import { Box, Button, Card, Grid, Stepper, Step, StepLabel, Typography, BottomNavigation } from '@mui/material';

const Owners = (props) => {
    const owners = props.owners;

    const handleChanges = (contacts) => {
        props.setFieldValue('owners', contacts) // save to Formik values on Register.js
        props.values.owners = contacts // update local copy of values with updates
        props.handleSave(props.values) // save to the database or Register.js
        //console.log('Owners/handleChanges/contacts',contacts)
    }

    return (
        <>
            <div className="form-group container">
            <h4>Owners</h4>
            {owners && <> 
                <DisplayContactGrid contacts={owners} handleChanges={handleChanges} name="owners" required={true} />
            </>}
            </div>
            <br /><br />
            <p>If an Owner is the same as the Applicant, please enter same information in this Owner's section.</p>
            <p>"SAME AS APPLICANT" inputs will not be accepted.</p>
            <p>If "SAME AS APPLICANT" shows above, please click Edit and input complete information.</p>
        </>
    );
}

export default Owners