import React, { useState, useContext } from "react";
import "../styles.css";
import {validateCase, formatContactNames} from "../functions/validation"
import { useLocation, useHistory, Redirect, useRegistrantRadioButtons } from 'react-router-dom';
import { UserContext } from "../App";

import { Icon } from '@iconify/react';
import {Autocomplete, Box, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import { isArray } from "lodash";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ADD_CASE_REG, AMEND_CASE_REG, VIEW_REG_CASES } from "../Queries";

export function DisplayCaseDataTable(props) {
  const showErrColumn = props.showErrColumn ? props.showErrColumn : false 
  const history = useHistory();

  const showCaseErrStatus = (errors, name) => {
    let complete = false
    if (isArray(errors)) {
      if(errors.length === 0) {complete = true}
    } else {
      complete = !Boolean(errors)
    }
    if (!complete) {
      //console.log(name, errors, 'incomplete')
      return <p style={{textAlign:'center'}}>
        <Icon icon="fluent:clipboard-error-20-regular" style={{ textAlign: 'center', color: 'red', fontSize: '36px' }} />
        <p style={{textAlign:'center', color: 'red'}}>INCOMPLETE</p>
      </p>
    } else {
      //console.log(name, errors, 'complete')
      return <p style={{textAlign:'center'}}>
        <Icon icon="carbon:task-complete" style={{ textAlign: 'center', color: 'green', fontSize: '36px' }}  />
        <p style={{textAlign: 'center', color: 'green'}}>COMPLETE</p>
      </p>
    }
  }

  const gotoPage = page => {
    history.push({pathname: page});
  }

  let date = props.caseData.filingDt ? `${props.caseData.filingDt}`.split("T")[0] : "No date provided"

  const caseErrors = showErrColumn ? validateCase(props.caseData) : []
  return (
    Boolean(props.caseData) &&
      <TableContainer component={Paper}>
      <Table bordered className="caseTable">
        <TableBody>
          <TableRow>
            <TableCell sx={{fontWeight: "bold"}}>Case Number</TableCell>
            <TableCell>{props.caseData.caseNbr}</TableCell>
            {showErrColumn && 
            <TableCell></TableCell>}
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: "bold"}}>Date of Application</TableCell>
            <TableCell>{date}</TableCell>
            {showErrColumn && 
            <TableCell></TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() => {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Description`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Project Description</TableCell>
            <TableCell>{props.caseData.projectDescription}</TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.projectDescription)}</TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() =>  {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Description`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Requested Entitlement</TableCell>
            <TableCell>{props.caseData.requestedEntitlement}</TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.requestedEntitlement)}</TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() =>  {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Address`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Project Address</TableCell>
            <TableCell>
              {props.caseData.primaryAddress} (Council District # {props.caseData.primaryCnclDistNbr}, Assessor Parcel #  {props.caseData.assessorParcelNumber})
            </TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.primaryAddress)}</TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() =>  {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Applicants`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Applicants</TableCell>
            <TableCell>{props.caseData && formatContactNames(props.caseData.applicants)}</TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.applicants, 'applicants')}</TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() => {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Owners`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Owners</TableCell>
            <TableCell>{props.caseData && formatContactNames(props.caseData.owners)}</TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.owners, 'owners')}</TableCell>}
          </TableRow>
          <TableRow hover={showErrColumn} onClick={() => {if (showErrColumn) {gotoPage(`/Register/${props.caseData.caseId}/Representatives`)}}}>
            <TableCell sx={{fontWeight: "bold"}}>Representatives</TableCell>
            <TableCell>{props.caseData && formatContactNames(props.caseData.representatives)}</TableCell>
            {showErrColumn && 
            <TableCell>{showCaseErrStatus(caseErrors.representatives, 'reps')}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
  );
}


export default DisplayCaseDataTable;

