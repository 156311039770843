/*libraries*/
import React, { useContext, useState } from "react";
import _ from "lodash";
import { Box, Button, Card, Grid, Stepper, Step, StepLabel, Typography, BottomNavigation } from '@mui/material';

/*components*/
import DisplayContactGrid from "./DisplayContactGrid";

const Representatives = (props) => {
    const representatives = props.representatives;

    const handleChanges = (contacts) => {
        props.setFieldValue('representatives', contacts); // save to Formik values on Register.js
        props.values.representatives = contacts // update local copy of values with updates
        props.handleSave(props.values) // save to the database or Register.js
    }

    return (
        <>
            <div className="form-group container">
            <h4>Representatives</h4>
             {representatives && <>
                <DisplayContactGrid contacts={representatives} handleChanges={handleChanges} name="representatives" required={false} />
                </>}
            </div>
        </>
    );
}

export default Representatives