import React, { useContext, useEffect, useRef } from 'react';
import { UserContext } from "./App";

import Container from "react-bootstrap/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import JSONPretty from "react-json-pretty";
import moment from 'moment';
import _ from "lodash";

// Material UI
import { Box, Button, Card, Grid, Stepper, Step, StepLabel, Typography, BottomNavigation } from '@mui/material';
import StepButton from '@mui/material/StepButton';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import HomeIcon from '@mui/icons-material/Home';

import { Formik, Form } from 'formik';

// COMPONENTS IMPORTS
import Description from './components/Description';
import Applicants from './components/Applicants';
import Owners from './components/Owners';
import Representatives from './components/Representatives';
import Address from './components/Address';
import Review from './components/Review';
import Esign from './Esign';
import PlanningCaseInfo from "./components/PlanningCaseInfo";

import { useOktaAuth } from '@okta/okta-react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { RESET_DOCUMENT_PDF_STATUS, EDIT_CASE_REG, VIEW_CASES, VIEW_REG_CASES } from "./Queries";
import UserValidation from "./Validations/UserValidation"
import * as yup from 'yup';
import { validateCase, caseRegCompleted } from './functions/validation';
import { consoleSandbox } from '@sentry/utils';


const useStyles = makeStyles({
  root: {
    "& .MuiStepIcon-active": { color: "red" },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  }
})

const c = useStyles;

const steps = ['Description', 'Address', 'Applicants', 'Owners', 'Representatives', 'Review'];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Register(props) {
  const userInfo = useContext(UserContext)
  const params = useParams();
  
  //console.log('Register params', params)

  const [openSaveAlert, setOpenSaveAlert] = React.useState(false);
  // grab the step from the url, then props, otherwise default to Description/0
  const step = params.step ? params.step : props.step ? props.step : steps[0]; // steps can be passed by url or by props, otherwise set to first.
  const isStep = (thisstep) => thisstep === step
  const activeStep = steps.findIndex(isStep);
  // grab the caseId from the url, then props, otherwise default to session variable (old way)
  const caseId = params.caseId ? params.caseId : props.caseId ? props.caseId : sessionStorage.getItem("caseId");

  //const [activeStep, setActiveStep] = React.useState(stepNum);
  //console.log('isStep', isStep, 'activeStep',activeStep)
  const [skipped, setSkipped] = React.useState(new Set());
  const history = useHistory();
  const [updCaseReg] = useMutation(EDIT_CASE_REG);
  /* reset PDF status on document to force PDF to re-render */
  const [resetDocumentPDFStatus] = useMutation(RESET_DOCUMENT_PDF_STATUS);

  const sessionCaseId = sessionStorage.getItem("caseId");
  const viewCaseReg = useQuery(VIEW_REG_CASES, { variables: { caseID: `${caseId}` }, fetchPolicy: 'network-only' })
  const viewCase = useQuery(VIEW_CASES, { variables: { caseID: `${caseId}` }, fetchPolicy: 'network-only' })

  
  if (viewCaseReg.loading || viewCase.loading) {
    return <Spinner color="dark" />; // show spinner while loading.
  }

  if (viewCaseReg.error || viewCase.error) {
    console.error(`Retrieve error! ${viewCaseReg.error}`)
    return <React.Fragment>Error :( </React.Fragment>;
  }

  //let caseRegData = JSON.parse(viewCaseReg.data.getCaseRegInfo.caseRegistrationData)
  //let caseData = JSON.parse(viewCase.data.getCaseInfo.caseData)
  
  let caseRegData = viewCaseReg.data.getCaseRegInfo ? viewCaseReg.data.getCaseRegInfo.caseRegistrationData : null
  let caseData = viewCase.data.getCaseInfo ? viewCase.data.getCaseInfo.caseData : null
  let amendNum = viewCaseReg.data.getCaseRegInfo ? viewCaseReg.data.getCaseRegInfo.amendNum : null
  let documentId = viewCaseReg.data.getCaseRegInfo ? viewCaseReg.data.getCaseRegInfo.document_id : null
  let documentStatusId = viewCaseReg.data.getCaseRegInfo ? viewCaseReg.data.getCaseRegInfo.document_status_id : null


  
  
  const initialValues= caseRegData // this fixes a Formik bug with validateOnMount (useRef causes error here... removed)

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };


  const handleSave = (values) => {
    //await new Promise((r) => setTimeout(r, 500));
    console.log(caseRegData.caseId, amendNum)
    updCaseReg({ variables: { caseID: `${caseRegData.caseId}`, amendNum: amendNum, caseRegistrationData: JSON.stringify(values) } })
    resetDocumentPDFStatus({ variables: { document_id: documentId } })
    setOpenSaveAlert(true);
    //console.log('handleSave', values)
  }

  const handleCloseSaveAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSaveAlert(false);
  };

  // change the url to match the step they are on as they use back/next buttons
  //const changeURL = (step) => window.history.pushState(document.state, document.title, `/${amendNum > 0 ? "Amend" : "Register"}/${caseId}/${steps[step]}`);
  const changeStep = (step) => history.push({ pathname: `/${amendNum > 0 ? "Amend" : "Register"}/${caseId}/${steps[step]}` })

  // change the page with refresh
  //const changePage = (path) => { history.push({ pathname: path }) }

  const handleNext = (values) => {
    handleSave(values)
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    //changeURL(activeStep + 1);
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    changeStep(activeStep + 1)
    setSkipped(newSkipped);
  };

  const handleStep = (step, values) => {
    handleSave(values)
    //setActiveStep(step);
    changeStep(step)
    //changeURL(step);
  };

  const handleBack = (values) => {
    handleSave(values)
    //changeURL(activeStep - 1);
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    changeStep(activeStep - 1)
  };

  /*const handleSkip = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };*/

  const handleReset = () => {
    //setActiveStep(0);
    history.push({ pathname: `/Register` })
  };

  /* window.addEventListener('popstate', function(event) {
    // The popstate event is fired each time when the current history entry changes.

    var r = true;

    if (r == true) {
        // Call Back button programmatically as per user confirmation.
        history.goBack();
        // Uncomment below line to redirect to the previous page instead.
         window.location = document.referrer // Note: IE11 is not supporting this.
    } else {
        // Stay on the current page.
        history.pushState(null, null, window.location.pathname);
    }

    history.pushState(null, null, window.location.pathname);

}, false) */


  // Placement matters or values will be off
  const getStepsContent = (index, values, setFieldValue, errors, touched, handleBlur, handleChange) => {
    switch (index) {
      case 0:
        return <Description touched={touched} errors={errors} handleBlur={handleBlur} />;
      case 1:
        return <Address touched={touched} errors={errors} handleBlur={handleBlur} />;
      case 2:
        return <Applicants applicants={values.applicants} setFieldValue={setFieldValue} touched={touched} errors={errors} handleBlur={handleBlur} values={values}  handleChange={handleChange} handleSave={handleSave} />;
      case 3:
        return <Owners owners={values.owners} setFieldValue={setFieldValue} touched={touched} errors={errors} handleBlur={handleBlur} values={values} handleSave={handleSave} />;
      case 4:
        return <Representatives representatives={values.representatives} setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} handleBlur={handleBlur} handleSave={handleSave} />;
      case 5:
        return <Review data={values} />;
      default:
        return "Unknown step";
    }
  }


  const regValidationSchema = yup.object().shape({
    projectDescription: 
    yup
    .string().required("Required").nullable()
    /* .string()
    .matches(/^(?=.*\S).+$/, 'Your Project Description name must be 1 or more characters')
    .required("Required")
    .nullable() */,
    /* requestedEntitlement: yup
    .string().required("Required").nullable() */
    primaryAddress: 
    yup
    .string().required("Required").nullable()
  });

  const actionButtons = (values) => {
    const registrationComplete = caseRegCompleted(values)
    return <>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

        {/* Show Back button for all but first step and if registration is complete */
          activeStep != 0 && documentStatusId < 2 ? (
            <Button
              className="yellowButton"
              onClick={() => {handleBack(values)}}
              value="back"
            >
              <ArrowBackIosNewIcon /> Back
            </Button>
          ) : (
            /* On first step, allow them to select another case */
            <>

              <Button
                className="yellowButton"
                component={Link}
                to={'/Register'}
                value="select case"
              >
                <ArrowBackIosNewIcon /> Select Case
              </Button>
            </>
          )
        }

        {/* Show Save and Next buttons for all but last step */
          activeStep != steps.length - 1 && (
            <>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                className="yellowButton"
                onClick={() => {handleSave(values)}}
                value="save"
              >
                <SaveIcon /> Save
              </Button>

              <Button
                className="yellowButton"
                onClick={() => {handleNext(values)}}
                value="next"
              >
                Next
                <ArrowForwardIosIcon />
              </Button>
            </>
          )}

        {/* On last step, direct them to signature page */
          activeStep == steps.length - 1 && (
            <>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button
                className={registrationComplete ? "yellowButton" : "disabledButton"}
                component={Link}
                to={`/Esign/${caseId}`}
                value="sign"
                disabled={!registrationComplete}
              >
                Sign
                <ArrowForwardIosIcon />
              </Button>
            </>
          )}

      </Box>
    </>
    }
  const getDate = moment().format('MMMM Do YYYY')

  return (
    caseRegData && (
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={openSaveAlert} autoHideDuration={3000} onClose={handleCloseSaveAlert}>
            <Alert onClose={handleCloseSaveAlert} severity="success" sx={{ width: '100%' }}>
              Registration Saved.
            </Alert>
          </Snackbar>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={amendNum > 0 ? 3 : 4}>
              <strong>Case</strong><br />{caseRegData.caseNbr}
            </Grid>
            <Grid item xs={amendNum > 0 ? 3 : 4}>
              <strong>APN</strong><br />{caseRegData.assessorParcelNumber}
            </Grid>
            <Grid item xs={amendNum > 0 ? 3 : 4}>
              <strong>Application Date</strong><br />{getDate}
            </Grid>
            {amendNum > 0 ?
              <Grid item xs={3}>
                <strong>Amendment:</strong><br />{amendNum}
              </Grid>
              : <></>}
          </Grid>

        </Box>
        <br />
        <Stepper nonLinear
          className={c.root}
          orientation="horizontal"
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton color="inherit" onClick={()=>{handleStep(index, caseRegData)}}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </StepButton>
              </Step>
              
            );
          })}                
        </Stepper>
        <br />
        {activeStep === steps.length - 1 && caseRegData.documentStatusId > 1 ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {amendNum > 0 ? "Amendment is complete." : "Registration is complete."}
              Save or print a copy of the signed form below.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button onClick={handleReset}>Register Another Case</Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button component={Link} to={`/Home`}>Main Menu</Button>
            </Box>
            <Esign caseId={caseId} />
          </React.Fragment>
        ) : (
          <Formik
            validateOnMount={true} //(causing error that doesn't have easy solution -- useRef causes a re-render situation)
            initialValues={initialValues}
            validationSchema={regValidationSchema}
          >
            {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
              caseRegData && (
                <>
                  {/* Needs to be wrapped around Form or causes page to refresh and bomb */}
                  <Form id="myForm">
                    {getStepsContent(activeStep, values, setFieldValue, errors, touched, handleBlur, handleChange,)}
                    {actionButtons(values)}
                  </Form>
                </>
              )
            )}
          </Formik>
        )}
        <Button
            className="yellowButton"
            component={Link} to={`/Home`}
            sx={{ mr: 1 }}
            value="home" 
        >
            <HomeIcon /> Main Menu
        </Button>
      </Container>
    )
  );
}
