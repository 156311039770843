import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';

import "bootstrap/dist/css/bootstrap.min.css";
import {TextField} from '@mui/material';

const Address = (props) => {

    return (
      <>
        <div className="form-group">
        <h4>Primary Address</h4>
            <Field 
              name="primaryAddress" 
              as={TextField} fullWidth 
              label="Primary Address" 
              multiline rows={4}
              onBlur={props.handleBlur}
              error={props.touched.primaryAddress && Boolean(props.errors.primaryAddress)}
              helperText={props.touched.primaryAddress && props.errors.primaryAddress} 
            />
        </div>
      </>
    );

}
 
export default Address;