import React, {useState, useEffect} from 'react'
import MUIDataTable from "mui-datatables";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Container, Paper } from "@material-ui/core/";
import { GET_CASE_REG_COMPLIANCE, VIEW_CASES, GET_CASES } from "../Queries";
import { Spinner } from 'reactstrap';
import axios from "axios";
import { ConstructionOutlined } from '@mui/icons-material';
import pako from 'pako';
import {Buffer} from 'buffer'

const ComplianceReport = () => {
    const {loading, error, data} = useQuery(GET_CASE_REG_COMPLIANCE);
    /* const [decompressedData, setDecompressedData] = useState([]); */
    if (loading) return <Spinner color="dark" />;
    if (error) return <React.Fragment>Error :(</React.Fragment>;

    const compressedData = data.getCaseRegCompliance[0].compressedData;

    // Decompress the Gzipped base64 data
    const buffer = Buffer.from(compressedData, 'base64');

    const decompressedBuffer = Buffer.from(pako.inflate(buffer));

    const decompressedString = decompressedBuffer.toString('utf8');

    const jsonData = JSON.parse(decompressedString);
    const decompressedData = jsonData;
    
    const extractPctsEmails = (contactInformation) => contactInformation ? contactInformation.filter(e => e.emailAddrNm != null).map(e =>e.emailAddrNm).join(", ") : ''

    const columns = [
        {
         name: "caseNbr",
         label: "Case Number",
         options: {
          filter: false,
          sort: true,
         }
        },
        {
            name: "primaryAddress",
            label: "Address",
            options: {
            filter: false,
             sort: true,
            }
           },
           {
            name: "contactInformation",
            label: "PCTS Emails",
            options: {
             display: true,
             filter: false,
             sort: false,
             customBodyRender: (value, tableMeta, updateValue) => extractPctsEmails(tableMeta.rowData[2])
            }
           },
        {
         name: "filingDt",
         label: "Date of Application",
         options: {
          filter: false,
          sort: true,
         }
        },
        {
           name: "name",
           label: "Applicant, Owners, Reps",
           options: {
            display: false,
            filter: false,
           }
          },
          {
           name: "completionDt",
           label: "Date Completed",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "determination",
           label: "Determination",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "restrictionEndDt",
           label: "Restriction Ends",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "dateRegistered",
           label: "Date Registered",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "regStatus",
           label: "Registration Status",
           options: {
            filter: true,
            sort: true,
           }
          },
          {
            name: "regUserFname",
            label: "First Name",
            options: {
             display: false,
             filter: false,
            }
           },
           {
            name: "regUserLname",
            label: "Last Name",
            options: {
             display: false,
             filter: false,
            }
           },
           {
            name: "User",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  let fname = tableMeta.rowData[10] ? tableMeta.rowData[10]: ''
                  let lname = tableMeta.rowData[11] ? tableMeta.rowData[11]: ''
                  let name = fname + ' ' + lname

                  return name.trim()
                }
                }
           },
          {
           name: "regUserEmail",
           label: "Email",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
            name: "yearFiled",
            label: "Year",
            options: {
             display: false,
             filter: true,
             filterType: 'textField',
             sort: true,
            }
           },
           {
            name: "monthFiled",
            label: "Month",
            options: {
             display: false,
             filter: true,
             sort: true,
            }
           },
           {
            name: "restrictionActiveFlg",
            // change this
            label: "Restriction Active",
            options: {
             display: false,
             filter: true,
             sort: true
            }
           },
           {
            name: "ethicsRegistrationEmailed",
            label: "Planning Email Notification",
            options: {
             display: true,
             filter: false,
             sort: true,
             filterType: 'date'
            }
           },
           {
            name: "ethicsRegistrationCompleted",
            label: "Planning Marked Complete",
            options: {
             display: true,
             filter: false,
             sort: true,
             filterType: 'date'
            }
           },
       ];
       
       
       const options = {
         filterType: 'dropdown',
         rowsPerPage: 10,
         rowsPerPageOptions: [10, 20, 50, 100],
         selectableRows: false,
         downloadOptions: {
          filename: 'RDFSCompliance.csv', 
          filterOptions: 
            {
              useDisplayedColumnsOnly: true, 
              useDisplayedRowsOnly: true
            }
          }
       };

  return (
    
      
    <MUIDataTable 
        title={"Compliance Report"}
        data={decompressedData}
        columns={columns}
        options={options}
      
    >
    </MUIDataTable>
      
    
    
    
  )
}

export default ComplianceReport