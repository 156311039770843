import React, { useContext, useState } from "react";
import {Box, Button, Icon, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {StylesProvider} from '@material-ui/core';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import _ from "lodash";
import DisplayContactForm from "./DisplayContactForm";
import { SettingsInputAntennaTwoTone } from "@mui/icons-material";
import UserValidation from "../Validations/UserValidation";
import {validateContact, formatContactName, getContactByCntctUuid} from "../functions/validation"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
   // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };

const DisplayContactGrid = (props) => {

    //state and handlers for add modal
    const [addOpen, setAddOpen] = React.useState(false);
    const handleAddOpen = () => setAddOpen(true);
    const handleAddClose = () => setAddOpen(false);
    const [addPrincipalOpen, setAddPrincipalOpen] = React.useState(false);
    const [principalOfUuid, setPrincipalOfUuid] = React.useState(false);
    const handleAddPrincipalOpen = (cntctUuid) => {
        setPrincipalOfUuid(cntctUuid)
        setAddPrincipalOpen(true)
    }
    const handleAddPrincipalClose = () => {
        setPrincipalOfUuid(null)
        setAddPrincipalOpen(false)
    };

    //state and handlers for edit modals (use index to track which one to edit)
    const [editOpenIndex, setEditOpenIndex] = React.useState(-1);
    const handleEditOpen = (index) => setEditOpenIndex(index);
    const handleEditClose = () => setEditOpenIndex(-1);

    //state and handlers for delete modals  (use index to track which one to edit)
    const [deleteOpenIndex, setDeleteOpenIndex] = React.useState(-1);
    const handleDeleteOpen = (index) => setDeleteOpenIndex(index);
    const handleDeleteClose = () => setDeleteOpenIndex(-1);

    let contacts = props.contacts;
    const getContactName = (contact) => contact.frstNm ? `${contact.frstNm} ${contact.lstNm ? contact.lstNm : ''}` : contact.lstNm ? contact.lstNm : contact.coBusnNm;
    const getContactCoBusnNm = (contact) => contact.coBusnNm === getContactName(contact) ? '' : contact.coBusnNm ? contact.coBusnNm :'';

    const handleAddContact = (contact) => {  
        contacts[contacts.length] = contact
        props.handleChanges(contacts)
        handleAddClose()
    }

    const handleAddPrincipal = (contact) => {  
        contacts[contacts.length] = contact
        props.handleChanges(contacts)
        handleAddPrincipalClose()
    }

    const handleUpdContact = (contact, index) => {
        contacts[index] = contact

        // If an organization with principals, also update org name on principals
        let newContacts = contacts
        if (contact.principalFlg != 'Y' && contact.indOrgFlg == 'O') {
            newContacts = contacts.map(thisContact => (
                thisContact.principalOfUuid === contact.cntctUuid ? 
                    thisContact.indOrgFlg === 'O' ?
                        { ...thisContact, principalOfCoBusnNm: contact.coBusnNm } 
                        : 
                        { ...thisContact, coBusnNm: contact.coBusnNm, principalOfCoBusnNm: contact.coBusnNm } 
                    :
                    thisContact
                ));
        }
        props.handleChanges(contacts)
          
        handleEditClose()
    }
    
    const handleDelContact = (index) => {
        //filter out contact being deleted.
        let filteredContacts = contacts.filter((contact, i) => index != i)
        contacts = filteredContacts
        props.handleChanges(contacts)
        handleDeleteClose()
    }

    const showIndOrgIcon = (indOrgFlg) => {
        switch(indOrgFlg) {
            case "I": return <p style={{textAlign:'center'}}><PersonIcon style={{ textAlign: 'center', fontSize: '28px' }} />
            <br />Individual</p>;
            case "O": return <p style={{textAlign:'center'}}><GroupsIcon style={{ textAlign: 'center', fontSize: '28px' }} />
            <br />Organization</p>;
            default:  return <p style={{textAlign:'center'}}><QuestionMarkIcon style={{ textAlign: 'center', fontSize: '28px' }} />
            <br />Unknown</p>;
            }
      }

    //checks a contact against an array of contacts to see if there are any linked principals
    const hasPrincipals = (cntctUuid) => {
        return Boolean(contacts.find(contact => contact.principalOfUuid == cntctUuid))
    }

    let contactsDisplay = 
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label={`${props.name} table`} size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Ind/Org</strong></TableCell>
                            <TableCell align="left"><strong>Name</strong></TableCell>
                            <TableCell align="left"><strong>Address</strong></TableCell>
                            <TableCell align="left"><strong>Email</strong></TableCell>
                            <TableCell align="left"><strong>Phone</strong></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        
                        {contacts.length == 0 ? 
                            <TableRow>
                            <TableCell colSpan={6}>
                            <p style={{color: props.required && 'red', textAlign: 'center'}}>No {props.name} reported.{props.required && ` You must report all ${props.name} associated with this case.`}</p>
                            </TableCell>
                            </TableRow> 
                            :
                            contacts.map((contact,index) => (
                            <>
                            <TableRow
                            key={index}
                            sx={{ "& td": { border: 0 } }}
                            >                        
                            <TableCell align="left" key={`${index}.IndOrgFlg`}>
                                {showIndOrgIcon(contact.indOrgFlg)}
                            </TableCell> 
                            <TableCell align="left" key={`${index}.name`}>{getContactName(contact)}<br/>{Boolean(contact.title) ? `${contact.title}, ` : ``}{getContactCoBusnNm(contact)}{contact.principalFlg == "Y" && (<><br /><em>(principal of {formatContactName(getContactByCntctUuid(contacts,contact.principalOfUuid))})</em></>)}</TableCell> 
                            <TableCell align="left" key={`${index}.address`}>{contact.strNmFull}<br/>{contact.cityNm}, {contact.stCd} {contact.zipCd}</TableCell>
                            <TableCell align="left" key={`${index}.email`}>{contact.emailAddrNm}</TableCell>
                            <TableCell align="left" key={`${index}.phone`}>{contact.phoneNumberFull}</TableCell>
                            </TableRow>

                            <TableRow key={`${index}.errrow`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" key={`${index}.errmsg`}  colSpan={6}>
                                <div style={{textAlign: 'center'}}>                            
                                {validateContact(contact,contacts) && // show error if there is one.
                                <div style={{color: "red", textAlign: 'center'}}>{validateContact(contact,contacts)}</div>}
                                <Button className="yellowButton" size="small" variant="contained" onClick={() => handleEditOpen(index)}><Icon component={EditIcon} onClick={() => handleEditOpen(index)} /> Edit</Button>
                                <Button className={hasPrincipals(contact.cntctUuid) ? "disabledButton" : "yellowButton"} variant="contained" size="small" disabled={hasPrincipals(contact.cntctUuid)}  onClick={() => handleDeleteOpen(index)}><Icon component={DeleteIcon} onClick={() => handleDeleteOpen(index)}/> Delete </Button>
                                {contact.indOrgFlg === "O" && contact.principalFlg != "Y" && <><Button className="yellowButton"  variant="contained" size="small" onClick={() => {handleAddPrincipalOpen(contact.cntctUuid)}}><PersonIcon /> Add Principal</Button></>}
                                </div>
                            </TableCell></TableRow>
                            </>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>                
                    <br />
                    <Button className="yellowButton" size="small" onClick={() => {handleAddOpen(true)}}>
                        <AddIcon /> {`Add New`} 
                    </Button>
                
                {contacts.map((contact,index) =>
                <> 
                    {/*Modal for Editing a Contact*/}
                    <Modal
                        open={editOpenIndex == index}
                        onClose={handleEditClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        key={`modal-edit-${index}`}
                    >   
                        <Box sx={style}>  
                            <DisplayContactForm contact={contact} index={index} saveBtnText="Save"  handleSave={handleUpdContact} {...props} />
                            <br/>
                            <button variant='contained' onClick={handleEditClose} className="btn btn-secondary">Close</button>
                        </Box>
                    </Modal>

                    {/*Modal for Deleting a Contact*/}
                    <Modal
                        open={deleteOpenIndex == index}
                        onClose={handleDeleteClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        key={`modal-delete-${index}`}
                    >
                        <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete?
                            <br />
                            <button 
                            variant='contained'
                            type="submit" 
                            value="Send"
                            size="small"
                            onClick={() =>{handleDelContact(index)}} 
                            className="button">
                                Yes
                            </button>
                            <button variant='contained' size="small" onClick={handleDeleteClose} className="button">No</button>
                        </Typography>
                        </Box>
                    </Modal>        
                </>
                )}
                {/* Modal for adding a contact */}
                <Modal
                    open={addOpen}
                    onClose={handleAddClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal-size"
                >     
                <Box sx={style}>  
                   <DisplayContactForm saveBtnText="Add" handleSave={handleAddContact}  {...props}/>
                    <br/>
                    <button variant='contained' onClick={handleAddClose} className="btn btn-secondary">Close</button>
                </Box>
                </Modal>
                
                {/* Modal for adding a principal */}
                <Modal
                    open={addPrincipalOpen}
                    onClose={handleAddPrincipalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal-size"
                    style={{overflow:'scroll'}}
                >     
                <Box sx={style}>  
                   <DisplayContactForm saveBtnText="Add Principal" handleSave={handleAddPrincipal} principalOfUuid={principalOfUuid} {...props}/>
                    <br/>
                    <button variant='contained' onClick={handleAddPrincipalClose} className="btn btn-secondary">Close</button>
                </Box>
                </Modal>
            </div>

 return (contactsDisplay)
    
}

export default DisplayContactGrid