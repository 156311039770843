import { isNull } from "lodash";

export const formatContactName = (contact) => {
    if (contact) {
    let contactName = "";
        if (contact.frstNm && contact.lstNm && contact.coBusnNm) {
            contactName = `${contact.frstNm.trim()} ${contact.lstNm.trim()}/ ${contact.coBusnNm.trim()}`;
        } else if (contact.frstNm && contact.lstNm && !contact.coBusnNm) {
            contactName = `${contact.frstNm.trim()} ${contact.lstNm.trim()}`;
        } else if (!contact.frstNm && contact.lstNm && !contact.coBusnNm) {
            contactName = `${contact.lstNm.trim()}`;
        } else if (!contact.frstNm && !contact.lstNm && contact.coBusnNm) {
            contactName = `${contact.coBusnNm.trim()}`;
        }

        let contactEmail = 'none given'
        if (Boolean(contact.emailAddrNm)) {contactEmail=contact.emailAddrNm}
        contactName = `${contactName} (${contactEmail})`
        return contactName.trim();
    } else {
        return null;
    }
  };

export const formatContactNames = (contacts) => {
    if (contacts) {
      /*let contactNames = contacts.reduce(function (contactNames, contact) {
        return `${contactNames} ${formatContactName(contact)}; `;
      }, "");*/
      let contactNameArray = contacts.map((contact) => formatContactName(contact))
      let contactNames = contactNameArray.join('; ')
      return contactNames;
    } else {
      return null;
    }
  };
export function validateContact(contact, contacts) {
    let contactErrors = []
    if (!contact.indOrgFlg){
        contactErrors.push("Organization/Individual")
    }
    if (!contact.frstNm && contact.indOrgFlg == "I"){
        contactErrors.push("First Name")
    }
    if (!contact.lstNm && contact.indOrgFlg == "I"){
        contactErrors.push("Last Name")
    }
    if (!contact.coBusnNm && contact.indOrgFlg == "O"){
        contactErrors.push("Business Name")
    }
    if (!contact.title && contact.indOrgFlg == "I" ){
        contactErrors.push("Title")
    }
    if (!contact.phoneNumberFull){
        contactErrors.push("Phone Number")
    }
    if (!contact.emailAddrNm){
        contactErrors.push("Email")
    }
    if (!contact.strNmFull){
        contactErrors.push("Street Address")
    }
    if (!contact.cityNm){
        contactErrors.push("City")
    }
    if (!contact.stCd){
        contactErrors.push("State")
    }
    if (!contact.zipCd){
        contactErrors.push("Zip Code")
    }
    /* if full array of contacts were passed in, we can check to see if principals were added */
    if(contacts && contact.indOrgFlg === "O" && contact.principalFlg != "Y") {
        let principals = getContactsByPrincipalOfUuid(contacts, contact.cntctUuid)
        //console.log('principals', principals, 'length', principals.length)
        if (principals.length == 0) {
            contactErrors.push("Principals")
        }
    }

    //console.log('contactErrors',contactErrors)
    let errorMsg = contactErrors.length > 0 ? `Missing: ${contactErrors.join(', ')}` : null
    //console.log('validateContact errorMsg', formatContactName(contact) ,errorMsg)

    return errorMsg
}

export function validateCase(caseData) {
    //console.log('validateCase', 'caseData', caseData)
    const caseErrors = {};
    if (Boolean(caseData)) {
        if (!caseData.projectDescription){
            caseErrors['projectDescription'] = "Missing: Project Description (required)"
            
        }
        if (!caseData.primaryAddress){
            caseErrors['primaryAddress'] = "Missing: Project Address (required)"      
        }

        if (Boolean(caseData.applicants)) {
            if (caseData.applicants.length == 0){
                caseErrors['applicants'] = ["Missing: Applicants (required)"]
            } else {
                caseErrors['applicants'] = caseData.applicants.map((applicant)=> {
                    let error = validateContact(applicant, caseData.applicants)
                    return error ? `${formatContactName(applicant)} - ${error}` : null
                })
                caseErrors['applicants'] = caseErrors['applicants'].filter(error => !isNull(error))

                if (caseErrors['applicants'].length === 0) {delete caseErrors['applicants']}

            }   
        } else {
            caseErrors['applicants'] = ["Missing: Applicants (required)"]
        }

        if (Boolean(caseData.owners)) {
            if (caseData.owners.length == 0){
                caseErrors['owners'] = ["Missing: Owners (required)"]
            } else {
                caseErrors['owners'] = caseData.owners.map((owner)=> {
                    let error = validateContact(owner, caseData.owners)
                    return error ?  `${formatContactName(owner)} - ${error}` : null
                })
                caseErrors['owners'] = caseErrors['owners'].filter(error => !isNull(error))
                if (caseErrors['owners'].length === 0) {delete caseErrors['owners']}
            }
        } else {
            caseErrors['owners'] = ["Missing: Owners (required)"]
        }

        if (Boolean(caseData.representatives)) {
            if (caseData.representatives){
                caseErrors['representatives'] = caseData.representatives.map((representative)=> {
                    let error = validateContact(representative, caseData.representatives)
                    return error ? `${formatContactName(representative)} - ${error}` : null
                })
                caseErrors['representatives'] = caseErrors['representatives'].filter(error => !isNull(error))
                if (caseErrors['representatives'].length === 0) {delete caseErrors['representatives']}
            }
        }
}
    //console.log('caseErrors', caseErrors)
    return(caseErrors)

}

export function caseRegCompleted(caseReg) {
    let caseErrors = validateCase(caseReg)
    let caseRegCompleted = Boolean(!Object.keys(caseErrors).length)
    //console.log('caseRegCompleted', caseRegCompleted, 'caseErrors)', caseErrors, 'Object.keys(caseErrors).length', Object.keys(caseErrors).length)
    return caseRegCompleted
}

export function getContactByCntctUuid(contacts, cntctUuid) {
    return contacts.filter(contact => contact.cntctUuid == cntctUuid)[0];
}

export function getContactsByPrincipalOfUuid(contacts, principalOfUuid) {
    return contacts.filter(contact => contact.principalOfUuid == principalOfUuid);
}

const states = [
    { names: 'Alabama', abbrev: 'AL' },
    { names: 'Alaska', abbrev: 'AK' },
    { names: 'Arizona', abbrev: 'AZ' },
    { names: 'Arkansas', abbrev: 'AR' },
    { names: 'California', abbrev: 'CA' },
    { names: 'Colorado', abbrev: 'CO' },
    { names: 'Connecticut', abbrev: 'CT' },
    { names: 'Delaware', abbrev: 'DE' },
    { names: 'Florida', abbrev: 'FL' },
    { names: 'Georgia', abbrev: 'GA' },
    { names: 'Hawaii', abbrev: 'HI' },
    { names: 'Idaho', abbrev: 'ID' },
    { names: 'Illinois', abbrev: 'IL' },
    { names: 'Indiana', abbrev: 'IN' },
    { names: 'Iowa', abbrev: 'IA' },
    { names: 'Kansas', abbrev: 'KS' },
    { names: 'Kentucky', abbrev: 'KY' },
    { names: 'Louisiana', abbrev: 'LA' },
    { names: 'Maine', abbrev: 'ME' },
    { names: 'Maryland', abbrev: 'MD' },
    { names: 'Massachusetts', abbrev: 'MA' },
    { names: 'Michigan', abbrev: 'MI' },
    { names: 'Minnesota', abbrev: 'MN' },
    { names: 'Mississippi', abbrev: 'MS' },
    { names: 'Missouri', abbrev: 'MO' },
    { names: 'Montana', abbrev: 'MT' },
    { names: 'Nebraska', abbrev: 'NE' },
    { names: 'Nevada', abbrev: 'NV' },
    { names: 'New Hampshire', abbrev: 'NH' },
    { names: 'New Jersey', abbrev: 'NJ' },
    { names: 'New Mexico', abbrev: 'NM' },
    { names: 'New York', abbrev: 'NY' },
    { names: 'North Carolina', abbrev: 'NC' },
    { names: 'North Dakota', abbrev: 'ND' },
    { names: 'Ohio', abbrev: 'OH' },
    { names: 'Oklahoma', abbrev: 'OK' },
    { names: 'Oregon', abbrev: 'OR' },
    { names: 'Pennsylvania', abbrev: 'PA' },
    { names: 'Rhode Island', abbrev: 'RI' },
    { names: 'South Carolina', abbrev: 'SC' },
    { names: 'South Dakota', abbrev: 'SD' },
    { names: 'Tennessee', abbrev: 'TN' },
    { names: 'Texas', abbrev: 'TX' },
    { names: 'Utah', abbrev: 'UT' },
    { names: 'Vermont', abbrev: 'VT' },
    { names: 'Virginia', abbrev: 'VA' },
    { names: 'Washington', abbrev: 'WA' },
    { names: 'West Virginia', abbrev: 'WV' },
    { names: 'Wisconsin', abbrev: 'WI' },
    { names: 'Wyoming', abbrev: 'WY' }
];