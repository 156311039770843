import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../App";
import "../styles.css";
import {caseRegCompleted} from "../functions/validation"
import { useTheme } from '@mui/material/styles';
import { useLocation, useHistory, Redirect, useParams, Link } from 'react-router-dom';
import { Card, CardHeader, CardMedia, CardContent, CardActions} from '@mui/material/';
import {Box, Button, Grid,  Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TablePagination, Typography} from '@mui/material';
import { Icon } from '@iconify/react';
import SelectCase from '../SelectCase';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { VIEW_REG_CASES_BY_USER, AMEND_CASE_REG } from "../Queries";
import { Spinner } from 'reactstrap';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomeIcon from '@mui/icons-material/Home';
import LastPageIcon from '@mui/icons-material/LastPage'
import _ from "lodash";
import Navbar from "./Navbar";
import { CANCEL_DOCUMENT } from "../Queries";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};
const MainMenu = () => {

    const userInfo = useContext(UserContext)
    //console.log('MainMenu userInfo', userInfo)

    /* Grab all Registrations related to this user */
    const userEmail = Boolean(userInfo) ? userInfo.email : null
    const viewCaseRegByUser = useQuery(VIEW_REG_CASES_BY_USER, { variables: {userEmail: userEmail}, fetchPolicy: 'network-only'})
    if (!userInfo || viewCaseRegByUser.loading){
      return <Spinner color="dark" />; // show spinner while loading.
    }
  
    if (viewCaseRegByUser.error){
      console.error(`Retrieve error! ${viewCaseRegByUser.error}`)
      return <React.Fragment>Error :( </React.Fragment>;
    }

    const caseRegistrations =  viewCaseRegByUser.data.getCaseRegByUser
    const caseRegistrationsInProgress = caseRegistrations.filter((caseReg) => caseReg.document_status_id == 0 || caseReg.document_status_id == 1);
    const caseRegistrationsCompleted = caseRegistrations.filter((caseReg) => caseReg.document_status_id > 1);
    
    let gridUnitSize  = 12
    let menuItemCount = 1
    if (caseRegistrationsInProgress && caseRegistrationsInProgress.length > 0) {menuItemCount+=1}
    if (caseRegistrationsCompleted && caseRegistrationsCompleted.length > 0) {menuItemCount+=1}
    if (userInfo.userSecLvl == 'AD') {menuItemCount+=1}

    gridUnitSize  = 12/menuItemCount

    return (
    <div>
        <Box alignItems="center" justifyContent="center">    
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary">
          If you have questions about registering, please contact the Ethics Commission at (213) 978-1960 or <a href="mailto:ethics.rdfs@lacity.org" target="_blank">ethics.rdfs@lacity.org</a>.
          </Typography>
        </Grid>
          <Grid item xs={12} sm={gridUnitSize}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image="/img/NewCasePhoto.jpg"
                alt="photo of building plans"
                title="Cropped Shot Of Drafting Paper And Tools On Table by Jacob Lund Photography from NounProject.com"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Register a New Case
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Register a new planning case with the Ethics Commission.
                </Typography>
              </CardContent>
              <CardActions>
              <Button
                className="yellowButton"
                style={{"margin":"auto"}}
                component={Link}
                to="/Register"
                value="register"
                >Register</Button>
              </CardActions>
            </Card>
          </Grid>
      
      {caseRegistrationsInProgress.length > 0 && (
      <Grid item xs={12} sm={gridUnitSize}>
        <Card>
          <CardMedia
            component="img"
            height="300"
            image="/img/InProgressPhoto.jpg"
            alt="under construction"
            title="Portrait Of Engineer Foreman In Hardhat Working On Construction Repair Checklist by winnievinzence from NounProject.com"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Registrations In Progress
            </Typography>
            <Typography variant="body2" color="text.secondary">
              You have {caseRegistrationsInProgress.length} planning case registration{caseRegistrationsInProgress.length > 1 ? "s": ""} in progress. 
            </Typography>
          </CardContent>
          <CardActions>
          <Button
            className="yellowButton"
                component={Link}
                to="/Registrations/InProgress"
            value="complete" 
            style={{"margin":"auto"}}
            >Complete and Sign</Button>
          </CardActions>
        </Card>
      </Grid>
      )}
     {caseRegistrationsCompleted.length > 0 && (
      <Grid item xs={12} sm={gridUnitSize}>
        <Card>
          <CardMedia
            component="img"
            height="300"
            image="/img/CompletedPhoto.jpg"
            alt="office building"
            title="Windows Of A Contemporary Office Building by Rosley Majid from NounProject.com"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Completed Registrations
            </Typography>
            <Typography variant="body2" color="text.secondary">
              You have {caseRegistrationsCompleted.length} completed planning case registration{caseRegistrationsCompleted.length > 1 ? "s": ""}.
            </Typography>
          </CardContent>
          <CardActions>
          <Button
            className="yellowButton"
                component={Link}
                to="/Registrations/Complete"
            value="amend" 
                style={{"margin":"auto"}}
            >View or Amend</Button>
          </CardActions>
        </Card>
      </Grid>
      )}
      {userInfo.userSecLvl == 'AD' &&
      <Grid item xs={12} sm={gridUnitSize}>
        <Card>
          <CardMedia
            component="img"
            height="300"
            image="/img/administration.jpg"
            alt="administration"
            title="Administration Menu"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Administration Menu
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Administrative functions.
            </Typography>
          </CardContent>
          <CardActions>
          <Button
            className="yellowButton"
                component={Link}
                to="/Admin"
            value="amend" 
                style={{"margin":"auto"}}
            >Go to Admin Menu</Button>
          </CardActions>
        </Card>
      </Grid>
      }
    </Grid>
        </Box>
            </div>

)
    
}

export default MainMenu