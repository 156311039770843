import React from 'react'

const PlanningCaseInfo = () => {
  let url = "https://planning.lacity.gov/contact/locations-hours"
  return (
    <div className="left-alignment">
      <br /><br />  
      <p style={{color:'red'}}>Planning case data is provided by the <a href={url} target="_blank" rel="noopener noreferrer">Los Angeles City Planning Department</a> and is available the next calendar day after an application is filed.</p>
      <p>If you have questions about your case or case number, please contact the Planning Department at (213) 482-7077 (Metro), (818) 374-5050 (Valley), or (310) 231-2598 (West LA).</p>  
      <p>If you have questions about registering, please contact the Ethics Commission at (213) 978-1960 or <a href="mailto:ethics.rdfs@lacity.org" target="_blank">ethics.rdfs@lacity.org</a>.</p>
    </div>
  )
}

export default PlanningCaseInfo